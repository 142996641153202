import MultipleDirectUploadInput from './MultipleDirectUploadInput.vue';

export default {
  name: 'OfferForm',
  components: {
    MultipleDirectUploadInput,
  },
  data: function () {
    return {
      subject: '',
      content: '',
      setting: undefined,
    };
  },
  computed: {
    isTakuhai: function () {
      return this.subject === 'takuhai';
    },
    haveSubject: function () {
      return this.subject === 'takuhai' || this.subject === 'takuhai_kit';
    },
    haveContent: function () {
      return this.content.length > 0;
    },
    valid: function () {
      return this.haveSubject && this.haveContent;
    },
  },
  mounted() {
    console.log('offer form mounted');
    this.setting = this.getSetting(this.$refs.input);
  },
  methods: {
    getSetting(input) {
      const fileInput = input;
      return {
        directUploadUrl: fileInput.dataset.directUploadUrl,
        name: fileInput.name,
      };
    },
  },
};
